<template>
  <el-card body-style="margin: -20px;" v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-s-operation"
              class="mb-0"
              size="medium"
              @click="openFilterEntriesModal()"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-card shadow="never" class="mt-1">
      <el-descriptions title="Total de Produtos" v-if="HasFilters">
        <el-descriptions-item label="Quantidade de Produtos">{{
          entries?.length
        }}</el-descriptions-item>
        <el-descriptions-item label="Peso total descrito"
          >{{
            numberFormatter.format(sumWeights(entries, "desc_weight") || 0)
          }}
          kg.</el-descriptions-item
        >
        <el-descriptions-item label="Peso total aferido"
          >{{
            numberFormatter.format(sumWeights(entries, "weight") || 0)
          }}
          kg.</el-descriptions-item
        >
        <el-descriptions-item label="Média valor por kg">
          {{ currencyFormatter.format(getPriceWeightAvg(entries) || 0) }}
        </el-descriptions-item>
        <el-descriptions-item label="Valor total">{{
          currencyFormatter.format(sumEntryPrices(entries) || 0)
        }}</el-descriptions-item>
      </el-descriptions>
      <el-empty v-else description="Insira filtros para obter o relatório">
        <el-button-group>
          <el-button type="primary" @click="updateFiltersFromDays(60)"
            >Últimos 60 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(30)"
            >Últimos 30 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(15)"
            >Últimos 15 dias</el-button
          >
          <el-button type="primary" @click="updateFiltersFromDays(7)"
            >Últimos 7 dias</el-button
          >
        </el-button-group>
      </el-empty>
      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        v-if="HasFilters"
        :data="getEntriesByProductCategory(entries)"
        style="width: 100%; z-index: 0"
      >
        <el-table-column label="categorias" prop="category"></el-table-column>
        <el-table-column label="compras" prop="length"> </el-table-column>
        <el-table-column label="peso aferido">
          <template #default="p">
            {{ `${numberFormatter.format(sumWeights(p.row, "weight"))}` }}
            kg.
          </template>
        </el-table-column>
        <el-table-column label="peso declarado">
          <template #default="p">
            {{ `${numberFormatter.format(sumWeights(p.row, "desc_weight"))}` }}
            kg.
          </template>
        </el-table-column>
        <el-table-column
          label="média de preço"
          :formatter="
            (r) => currencyFormatter.format(Number(getPriceWeightAvg(r)) || 0)
          "
        ></el-table-column>
        <el-table-column label="valor total">
          <template #default="p">
            {{ `${currencyFormatter.format(sumEntryPrices(p.row))}` }}
          </template>
        </el-table-column>
      </el-table>
      <el-row v-show="Entries?.data?.length && HasFilters">
        <el-col :md="4">
          <p class="summary summary-black">Total:</p>
        </el-col>
        <el-col :md="4"> </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ numberFormatter.format(sumWeights(entries, "weight") || 0) }} kg.
          </p>
        </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{
              numberFormatter.format(sumWeights(entries, "desc_weight") || 0)
            }}
            kg.
          </p>
        </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ currencyFormatter.format(getPriceWeightAvg(entries) || 0) }}
          </p>
        </el-col>
        <el-col :md="4">
          <p class="summary summary-black">
            {{ currencyFormatter.format(sumEntryPrices(entries) || 0) }}
          </p>
        </el-col>
      </el-row>
    </el-card>
    <div v-if="HasFilters">
      <el-card
        shadow="never"
        class="mt-1"
        v-for="(p, providerUid) in getEntriesByProvider()"
        :key="p"
      >
        <el-descriptions :title="getProviderByUid(providerUid)?.name">
          <el-descriptions-item label="Total de Entradas">{{
            p?.length
          }}</el-descriptions-item>
          <el-descriptions-item label="Peso total descrito"
            >{{
              numberFormatter.format(sumWeights(p, "desc_weight") || 0)
            }}
            kg.</el-descriptions-item
          >
          <el-descriptions-item label="Peso total aferido"
            >{{
              numberFormatter.format(sumWeights(p, "weight") || 0)
            }}
            kg.</el-descriptions-item
          >
          <el-descriptions-item label="Média valor por kg.">
            {{ currencyFormatter.format(getPriceWeightAvg(p) || 0) }}
          </el-descriptions-item>
          <el-descriptions-item label="Valor total">{{
            currencyFormatter.format(sumEntryPrices(p) || 0)
          }}</el-descriptions-item>
        </el-descriptions>
        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          :data="getEntriesByProductName(p)"
          style="width: 100%; z-index: 0"
        >
          <el-table-column label="produto" prop="product"></el-table-column>
          <el-table-column label="compras" prop="elements.length">
          </el-table-column>
          <el-table-column label="peso aferido">
            <template #default="p">
              {{
                `${numberFormatter.format(
                  sumWeights(p.row.elements, "weight")
                )}`
              }}
              kg.
            </template>
          </el-table-column>
          <el-table-column label="peso declarado">
            <template #default="p">
              {{
                `${numberFormatter.format(
                  sumWeights(p.row.elements, "desc_weight")
                )}`
              }}
              kg.
            </template>
          </el-table-column>
          <el-table-column
            label="média de preço"
            :formatter="
              (r) =>
                currencyFormatter.format(
                  Number(getPriceWeightAvg(r.elements)) || 0
                )
            "
          ></el-table-column>
          <el-table-column label="valor total">
            <template #default="p">
              {{
                `${currencyFormatter.format(sumEntryPrices(p.row.elements))}`
              }}
            </template>
          </el-table-column>
        </el-table>
        <el-row v-show="Entries?.data?.length">
          <el-col :md="4">
            <p class="summary summary-black">Total:</p>
          </el-col>
          <el-col :md="4"> </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ numberFormatter.format(sumWeights(p, "weight") || 0) }} kg.
            </p>
          </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ numberFormatter.format(sumWeights([p], "weight") || 0) }} kg.
            </p>
          </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ currencyFormatter.format(getPriceWeightAvg(p) || 0) }}
            </p>
          </el-col>
          <el-col :md="4">
            <p class="summary summary-black">
              {{ currencyFormatter.format(sumEntryPrices(p) || 0) }}
            </p>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <filter-entries-modal
      :showModal="showEntriesModal"
      :options="filterOptions"
      @close-modal="showEntriesModal = false"
      @update-filters="updateFilters"
    ></filter-entries-modal>
  </el-card>
</template>

<script>
import FilterEntriesModal from "./modals/FilterEntriesModal.vue";
import EntryService from "../services/entries";
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
} from "../utils/formatters";

export default {
  name: "EntriesReportPage",
  components: {
    FilterEntriesModal,
  },
  data: () => ({
    hasError: false,
    isLoading: false,
    showEntriesModal: false,
    entries: [],
    isLoadingDownloadButton: false,
    dateFormatter,
    currencyFormatter,
    numberFormatter,
    partners: null,
    filterOptions: {},
  }),
  mounted() {
    this.fetchEntries();
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Entries() {
      return { data: this.entries || [] };
    },
    HasFilters() {
      return !!Object.keys(this.filterOptions)?.length;
    },
  },
  methods: {
    updateFiltersFromDays(days) {
      const created_at_start = new Date();
      created_at_start.setDate(created_at_start.getDate() - days);
      created_at_start.setHours(0, 0);

      this.updateFilters({
        ...(this.filterOptions || {}),
        created_at_start,
      });
    },
    getEntriesByProductCategory(entries) {
      const a = entries.reduce((t, a) => {
        if (t[a.product?.category?.name]) t[a.product?.category?.name].push(a);
        else {
          t[a.product?.category?.name] = [a];
          t[a.product?.category?.name]["category"] = a.product?.category?.name;
        }
        return t;
      }, {});

      return Object.values(a || {});
    },
    getEntriesByProvider() {
      return this.Entries?.data?.reduce((t, a) => {
        if (t[a?.provider?.uid]) t[a?.provider?.uid]?.push(a);
        else t[a?.provider?.uid] = [a];
        return t;
      }, {});
    },
    getEntriesByProductName(entries) {
      return Object.entries(
        entries?.reduce((t, e) => {
          if (t[e.product?.name]) t[e.product.name].push(e);
          else t[e.product?.name] = [e];
          return t;
        }, {})
      )?.map((e) => ({ product: e[0], elements: [...e[1]] }));
    },
    getProviderByUid(uid) {
      return this.partners?.find((p) => p.uid === uid) || null;
    },
    sumWeights(entries, weightKey = "weight") {
      return entries?.reduce((t, e) => (t += Number(e[weightKey]) || 0), 0);
    },
    sumEntryPrices(entries) {
      return entries?.length
        ? entries?.reduce(
            (t, e) => (t += Number(e.desc_weight) * Number(e.price_per_kg)),
            0
          )
        : 0;
    },
    getPriceWeightAvg(entries) {
      return (
        entries?.reduce((t, e) => (t += Number(e.price_per_kg)), 0) /
        entries?.length
      );
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    updateFilters(filters) {
      this.filterOptions = { ...(filters || {}), status: "included" };
      this.fetchEntries();
    },
    openFilterEntriesModal() {
      this.showEntriesModal = true;
    },
    getPrice(entries) {
      return entries[0] ? Number(entries[0].price) : 0;
    },
    fetchPartners() {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({ is_provider: true });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    async fetchEntries() {
      if (!this.HasFilters) return;

      this.isLoading = true;

      const { entries } = await EntryService().index(this.filterOptions);

      if (entries) this.entries = entries;

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary-black {
  color: black;
}
.summary {
  color: #333;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: center;
}
.mt-1 {
  margin-top: 20px;
}
</style>