export const HTTP_SUCCESS = 200;
export const HTTP_FAIL = 500;

export const BILLET_STATUS = [
  {
    label: "Cadastrado",
    value: "created",
  },
  {
    label: "Registrado",
    value: "registered",
  },
  {
    label: "Erro",
    value: "error",
  },
  {
    label: "Pago",
    value: "paid",
  },
];
