import { HTTP_SUCCESS } from "../utils/constants";
import store from "../store";

export default {
  async index(path, params) {
    const url = new URL(`${store.state.apiUrl}${path}`);
    if (params) url.search = new URLSearchParams(params);
    return fetch(url, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.status === HTTP_SUCCESS) return r.json();
      else throw r.json();
    });
  },
  async get(path, uid, query) {
    const url = new URL(`${store.state.apiUrl}${path}/${uid}`);

    if (query)
      url.search = new URLSearchParams(query);


    return fetch(url, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.status === HTTP_SUCCESS) return r.json();
      else throw r.json();
    });
  },
  async post(path, data) {
    const url = new URL(`${store.state.apiUrl}${path}`);

    return fetch(url, {
      credentials: "include",
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.status === HTTP_SUCCESS) return r.json();
      else throw r.json();
    });
  },
  async put(path, uid, data) {
    const url = new URL(`${store.state.apiUrl}${path}/${uid}`);

    return fetch(url, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.status === HTTP_SUCCESS) return r.json();
      else throw r.json();
    });
  },
  async delete(path, uid) {
    const url = new URL(`${store.state.apiUrl}${path}/${uid}`);

    return fetch(url, {
      credentials: "include",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((r) => {
      if (r.status === HTTP_SUCCESS) return r.json();
      else throw r.json();
    });
  },
};
