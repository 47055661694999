<template>
  <el-dialog title="Filtrar compras" v-model="shouldShow">
    <form>
      <el-row type="flex">
        <el-col> </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Fornecedor:</h4>
      </el-row>
      <el-select
        v-model="filterOptions.provider_id"
        clearable
        remote
        :remote-method="fetchPartners"
        filterable
        size="medium"
      >
        <el-option
          v-for="item in Partners"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
      <el-row type="flex" justify="start">
        <h4>Período de cadastro:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.created_at_start"
            type="date"
            value-format="YYYY-MM-DD HH:mm:ss"
            size="medium"
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.created_at_end"
            type="date"
            size="medium"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="Fim:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Peso da entrada:</h4>
      </el-row>
      <el-row type="flex" justify="space-between" :gutter="8">
        <el-col :md="12" :xs="24">
          <base-input
            type="money"
            placeholder="A partir:"
            v-model="filterOptions.weight_start"
          ></base-input>
        </el-col>
        <el-col :md="12" :xs="24">
          <base-input
            type="money"
            placeholder="Até:"
            v-model="filterOptions.weight_end"
          ></base-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Preço por kg:</h4>
      </el-row>
      <el-row type="flex" justify="space-between" :gutter="8">
        <el-col :md="12" :xs="24">
          <base-input
            type="money"
            placeholder="A partir:"
            v-model="filterOptions.price_start"
          ></base-input>
        </el-col>
        <el-col :md="12" :xs="24">
          <base-input
            type="money"
            placeholder="Até:"
            v-model="filterOptions.price_end"
          ></base-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Qualidade:</h4>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="clearFilter"
          >Limpar filtros</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="updateFilters"
          >Filtrar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
export default {
  props: ["showModal", "options"],
  emits: ["close-modal", "update-filters"],
  components: {
    BaseInput,
  },
  data() {
    return {
      filterOptions: {},
      isLoadingSave: false,
      firms: null,
      standards: null,
      partners: null,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchPartners();
    this.fetchStandards();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Standards() {
      return this.standards || [];
    },
    Firms() {
      return this.firms || [];
    },
    TypeOptions() {
      return [
        { label: "Dianteiro", value: "front" },
        { label: "Traseiro", value: "back" },
      ];
    },
    GenderOptions() {
      return [
        { label: "Macho", value: "male" },
        { label: "Fêmea", value: "female" },
      ];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  watch: {
    options(v) {
      if (v) {
        this.filterOptions = v;
      }
    },
  },
  methods: {
    fetchStandards() {
      const url = new URL(`${this.$store.state.apiUrl}system/preferences`);
      url.search = new URLSearchParams({
        key: "defined_standards",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.standards = e));
    },
    fetchPartners(name) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: name || " ",
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    updateFilters() {
      this.$emit("update-filters", this.filterOptions);
      this.shouldShow = false;
    },
    clearFilter() {
      this.filterOptions = {};
      this.updateFilters();
    },
  },
  name: "FilterAnimalsModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>